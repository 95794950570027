import $ from 'jquery';
import './index.scss';
import './world.js';
import './country-sectors.js';
$(document).ready(function(){
	$(document).on('click', '.wp-block-webfactory-map .marker', function(e){
		e.preventDefault();
		let target = $(this).attr('href');
		if (!$(target).length){
			console.error('mapmarker: invalid target');
			return;
		}
		$(this).parent().find('.active').removeClass('active');
		$(target).parent().find('.active').removeClass('active');
		$(this).addClass('active');
		$(target).addClass('active');
	})
})