import $ from 'jquery';
import './country-sectors.scss'
$.fn.webfactoryMapCountySectors = function(){
	let $navType = $(this).find('.block-map-nav-by-type .block-map-nav-item');
	let $navCity = $(this).find('.block-map-nav-by-city');
	let $navCityList = $(this).find('.block-map-nav-by-city-list');
	let $nav = $(this).find('[role="navigation"]')
	let $input = $(this).find('[role="data"]')
	let data = JSON.parse($input.val());
	let transform = `scale(${data.pan.z}) translate(${data.pan.x}%,${data.pan.y}%)`;
	let $svg = $(this).find('svg').addClass('has-selected-country')
	$svg.css('transform', transform)
	$svg.find(data.path.split('|').filter(x=>x).map(x=>'#'+x).join(',')).addClass('selected-country');
	$navType.click(function(){
		$navType.removeClass('active')
		$(this).addClass('active');
		$svg.find('.map--city.active').removeClass('active');
		let active = $(this).data('entityCities');
		$svg.find('.map--city').each(function(){

			if (active.includes($(this).data('id'))){
				$(this).addClass('active');
			}
		})
	})
	$(this).find('.block-map-nav-item-link').click(function(e){
		e.preventDefault();
	});

	$navCityList.on('change', '[name]', function(e){
		$navCity.find('.active').removeClass('active');
		$nav.attr('data-mode', 'city');
		let id =  $(this).find(":selected").val();
		console.log(id);
		$navCity.find('[data-city-id="'+id+'"]').addClass('active');

	})

	$(this).find('.block-map-nav-item-panel-close').click(function(e){
		$navType.removeClass('active')
		$svg.find('.map--city.active').removeClass('active');
		$nav.attr('data-mode', 'type');
		
		e.stopPropagation();
		e.preventDefault();		
	})

	$svg.on('click', '.map--city',function(){
		
		$navCity.find('.active').removeClass('active');
		let id = $(this).data('id');
		$nav.attr('data-mode', 'city');
		$navCity.find('[data-city-id="'+id+'"]').addClass('active');
	})


	let insertCity = (city, country )=>{
		let data = {r:6, z:country.pan.z, ...city.pan }
		var circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
		var gCity = document.createElementNS("http://www.w3.org/2000/svg", "g");
		var gLabel = document.createElementNS("http://www.w3.org/2000/svg", "g");
		gLabel.setAttribute('class', 'map--city--legend')
		circle.setAttribute("cx", 0);
		gCity.appendChild(circle)
		gCity.setAttribute('data-country', country.path)
		gCity.setAttribute('data-id', city.id)
		gCity.setAttribute('transform', `translate(${data.x}, ${data.y})`)
		gCity.setAttribute('class', `map--city`)

		circle.setAttribute("cy", 0);
		circle.setAttribute("r", data.r/data.z);  
		circle.setAttribute("fill", data.fill || "#333"); 
		$svg[0].appendChild(gCity);
		gCity.appendChild(gLabel);
		let rCircle = data.r/data.z
		
		
		if (city.label){
			
			let lang = ($('#viewport_worldmap')) ? $('#viewport_worldmap').attr('data-lang') : 'en'
			var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
			text.textContent = (lang==='fr') ? city.label.fr : city.label.en
			text.setAttribute('y', 4*rCircle);
			text.setAttribute('text-anchor', 'middle');
			text.setAttribute('font-size', (Math.floor(1200/data.z)/100)+'px')
			gLabel.appendChild(text)
			var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
			const tBox = (text.getBBox())
			rect.setAttribute('x', -tBox.width/2-4/data.z)
			rect.setAttribute('y', rCircle*2)
			rect.setAttribute('width', tBox.width+8/data.z)
			rect.setAttribute('height', 16/data.z)
			rect.setAttribute('fill', 'var(--wp--preset--color--secondary)')
			text.setAttribute('fill', 'white')
			var tr = document.createElementNS("http://www.w3.org/2000/svg", "path");
			tr.setAttribute('d', "M0 0L5 5 L-5 5Z");
			tr.setAttribute('transform', `translate(0, ${rCircle}) scale(${2/data.z}) `)
			tr.setAttribute('fill', 'var(--wp--preset--color--secondary)')
			gLabel.prepend(tr)

			gLabel.prepend(rect)
		}
	}
	data.cities.map(city=>{
		insertCity(city, data);
	})
}

$(document).ready(function(){
	$('.wp-block-webfactory-map[data-template="country-sectors"]').each(function(){
		$(this).webfactoryMapCountySectors();
	})
})