import $ from 'jquery';
import './world.scss';
$.fn.webfactoryMapWorld = function(){
	$('.has-animation').removeClass('has-animation');
	let $svg = $(this).find('[role="viewport"] svg');
	let x = $(this).find('[role="data"]').val();
	let data = JSON.parse(x);
	let state = {};
	let $regionTitle = $(this).find('.wp-block-webfactory-map-world-region-title')
	let $headerTitle = $(this).find('.wp-block-webfactory-map-world-title, .wp-block-webfactory-map-world-subtitle')
	let $nav = $(this).find('[role="navigation"]')
	let setPan = ({x,y,z})=>{
		let transform = `scale(${z}) translate(${x}%,${y}%)`;
		$svg.css({transform})
	}

	let getRegion = (id)=>{
		let $region = $nav.find('[data-region-id="'+id+'"]')
		if ($region.length){
			return  $region.data('region')
		}
		return null
	}
	data.map(c=>{
		let sel = c.path.split('|').map(x=>"#"+x).join(',')
		$svg.find(sel).addClass('active-country').attr('data-region', c.region).attr('data-href', c.url)
	})
	$svg.on('mouseenter', '.active-country', function(e){
		if (state.currentRegion){
			return;
		}
		let r = $(this).data('region');
		$svg.find('[data-region="'+r+'"]').addClass('--highlight')
	}).on('mouseleave', '.active-country', function(e){
		let r = $(this).data('region');
		$svg.find('[data-region="'+r+'"]').removeClass('--highlight')
	})
	$svg.on('click','.map-world-country[id]', function(e){
		let id = $(this).attr('id');
		let country = data.find(x=>x.path.split('|').includes(id))
		if (!state.currentRegion){
			e.preventDefault();
			if (!country){
				return;
			}
			$nav.find('[data-region-id="'+country.region+'"]').click();
			e.stopPropagation();
			return;
		}
		if ($(this).data('href')){
			e.stopPropagation();

			window.location.href = $(this).data('href')
			return
		}

	});
	$nav.on('click', '[data-region-id]', function(e){
		e.preventDefault();
		e.stopPropagation()
		let data = $(this).data('region');
		if ($(this).data('regionId')!=0){
			state.currentRegion = data;
		}
		else{
			state.currentRegion = 0;
		}
		$nav.find('[data-region-id]').removeClass('active');
		$(this).addClass('active')
		let pan = data.pan.split('|');
		setPan({x:pan[0], y:pan[1], z:data.zoom})
		$headerTitle.hide();
		$regionTitle.html($(this).text()).show()
	}) 
	$nav.on('click', '[data-region-id="0"]', function(e){
		$regionTitle.hide();
		$headerTitle.show();
		state.currentRegion = null;
		//$nav.find('[data-region-id="0"]').click();
	})

	$nav.on('click', '#toggle-btn', function(e){		
		if ($(this).is(':checked')) {
			$(this).attr('aria-checked',true);
			$nav.find('.nav').addClass('toggleOn');
			$nav.find('[data-action=collapse]').hide();
			$('.nav-has-children').show();
		} else {
			$(this).attr('aria-checked',false);
			$nav.find('.nav').removeClass('toggleOn');
			$nav.find('[data-action=collapse]').show();
			$('.nav-has-children').hide();
		}
	});
}
$.fn.webfactoryMapWorldMobile = function(){
	$('.block-map-world-nav-by-country-list').on('change', '[name]', function(e){
		let url = $(this).val();
		let urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
		if (url && urlPattern.test(url)) {
			  window.location.href = url;
		}
	});
}
$(document).ready(function(){
	$('.wp-block-webfactory-map[data-template="world"]').each(function(){		
		$(this).webfactoryMapWorld();
		$(this).webfactoryMapWorldMobile();		
	})
})